import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import { ButtonExtLink } from 'components/Shared/_Links'
import PolicyNav from 'components/member/PolicyNav'

import policyFile from 'assets/files/member/2018學年_規章.pdf'
import rulesFile from 'assets/files/member/晶晶學生應遵守事項_201309.pdf'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.dl`
  width: clamp(18ch, 75ch, 100%);
  margin-bottom: calc(var(--margin-default) * 2.5);
  p {
    --text-align: left;
    &:last-child {
      --margin-bottom: var(--margin-default);
    }
  }
  .alert {
    background-color: var(--color-alert);
    color: var(--color-white);
    padding: 0.2rem 0.5rem;
  }
  ul {
    /* margin-top: calc(var(--margin-default)*-1); */
  }
  li {
    padding-left: 1rem;

    &:before {
      content: '*';
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      opacity: 0.8;
    }
  }
  .desc {
    --text-align: center;
    &:first-of-type {
      --margin-bottom: 0;
    }
  }
  .file {
    margin-bottom: calc(var(--margin-default) * 1.5);
    &:first-of-type {
      margin-right: 2rem;
    }
  }
`
const ButtonContainer = styled.div`
  --border-radius: 0.5rem;
`
//#endregion

const Policy = ({ data, location }) => {
  const {
    policy: { body },
    seo,
  } = data
  const { title, description } = seo.frontmatter.policy

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <PolicyNav location={location} />
      <Title>晶晶兒童合唱團台灣團規章</Title>
      <Wrapper>
        <p className='desc'>規章2018學年 - 2018/8/1~2019/7/31</p>
        <p className='desc'>合唱團保留更改規章的權利，若有變更則另行通知。</p>

        <ButtonContainer>
          <ButtonExtLink className='file' href={policyFile} download>
            「規章 2018學年」詳細說明下載
          </ButtonExtLink>
          <ButtonExtLink className='file' href={rulesFile} target='_blank'>
            晶晶團員應遵守事項
          </ButtonExtLink>
        </ButtonContainer>

        <MDXRenderer>{body}</MDXRenderer>
      </Wrapper>
    </PageContent>
  )
}

export default Policy

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
      frontmatter {
        policy {
          title
          description
        }
      }
    }

    policy: mdx(frontmatter: { slug: { eq: "policy" } }) {
      body
    }
  }
`
